<template>
  <div class="FollowedStreamsManager">
    <el-collapse v-model="openedBrands">
      <el-collapse-item
        v-for="(brand, brandIndex) in brands"
        :key="brand.id"
        :name="brand.id"
        class="FollowedStreamsManager__brand"
      >
        <div slot="title" class="is-flex is-aligned-middle">
          <round-avatar
            :src="brand.logo.cropped"
            :acronym-fallback="brand.name"
            size="xsmall"
          />
          <div class="is-expanded p-l-m p-r-m">
            {{ brand.name }}
          </div>
          <div class="m-r-s">
            {{ brand.user_followed_streams_count }} / {{ brand.streams_count }}
          </div>
        </div>
        <followed-streams-manager-item
          :brand="brand"
          :is-visible="openedBrands.includes(brand.id)"
          data-testid="FollowedStreamsManagerItem"
          @update:brand="handleBrandUpdate(brandIndex, $event)"
        />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import FollowedStreamsManagerItem from '@/pages/settings/components/FollowedStreamsManagerItem'

/**
 * @module FollowedStreamsManager
 */
export default {
  name: 'FollowedStreamsManager',
  components: { FollowedStreamsManagerItem },
  props: {
    userId: {
      type: String,
      required: true
    },
    brands: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      openedBrands: []
    }
  },
  methods: {
    /**
     * Updates a specific brand by overwriting it in the brands array.
     * @param {number} brandIndex
     * @param {object} brandData
     */
    handleBrandUpdate (brandIndex, brandData) {
      let updatedBrands = this.brands.slice(0)
      updatedBrands[brandIndex] = brandData
      this.$emit('update:brands', updatedBrands)
    }
  }
}
</script>

<style lang='scss'>
@import '@/assets/styles/vueStyleUtils.scss';

.FollowedStreamsManager__brand {
  margin-bottom: $margin;

  .el-collapse-item__wrap {
    background: $hf__color-gray--light;
    padding: 0 $padding-small;
  }

  .el-collapse-item__header {
    background: $hf__color-gray--light;
    padding: 0 $padding-small;
  }
}
</style>
